<template>
    <div class="page_container">
        <div class="query_header">
            <div class="query_conditions">
                <div class="condition_item">
                    <span class="condition_label">模版名称</span>
                    <span class="condition_content">
                        <el-input
                            placeholder="请输入内容"
                            v-model.trim="title"
                            :maxlength="32"
                            show-word-limit
                            clearable
                            @keyup.enter.native="onPaginationChange('page', 1)"
                        />
                    </span>
                </div>
            </div>
            <div class="opt_container">
                <el-button @click="onReset">重置</el-button>
                <el-button type="primary" @click="onPaginationChange('page', 1)">查询</el-button>

                <el-button
                    v-if="P(1030701)"
                    class="float_left_btn"
                    type="primary"
                    plain
                    @click="onShowEditMirageTpl(null)"
                >
                    新建
                </el-button>
            </div>
        </div>

        <el-table :data="tableData" stripe border>
            <el-table-column align="center" type="index" label="序号" width="72" />
            <el-table-column header-align="center" prop="title" label="模版名称" />
            <el-table-column header-align="center" prop="courseInfo.title" label="直播课程" />
            <el-table-column align="center" prop="pushNum" label="推送消息数" width="100" />
            <el-table-column align="center" label="创建时间" prop="addTime" width="160" />
            <el-table-column align="center" width="80" label="操作">
                <template slot-scope="scope">
                    <div class="mini_link">
                        <el-link v-if="P(1030701)" type="primary" @click="onShowEditTplConfig(scope.row)">
                            推送设置
                        </el-link>
                        <el-link v-if="P(1030701)" type="info" @click="onShowEditMirageTpl(scope.row)">
                            编辑模版
                        </el-link>
                        <el-popconfirm v-if="P(1030702)" title="确定删除吗？" @confirm="onDelTpl(scope.row)">
                            <el-link type="danger" slot="reference">删除模版</el-link>
                        </el-popconfirm>
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
            background
            :page-sizes="[10, 20, 50, 100]"
            @size-change="(val) => onPaginationChange('size', val)"
            @current-change="(val) => onPaginationChange('page', val)"
            :current-page.sync="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, sizes"
            :total="total"
        />

        <EditMirageTpl :isShow="isShowEditMirageTpl" :activeItem="activeItem" @onClose="onCloseEditMirageTpl" />
        <TplConfig :isShow="isShowTplConfig" :activeItem="activeItem" @onClose="onCloseEditTplConfig" />
    </div>
</template>
<script>
import { getCourseTplList, delCourseTpl, getCourseList } from '@/js/api/courseApi.js'
import { filterQueryEmpty } from '@/js/utils.js'
import EditMirageTpl from './components/mirageTpl/EditMirageTpl.vue'
import TplConfig from './components/mirageTpl/TplConfig.vue'

export default {
    components: { EditMirageTpl, TplConfig },
    data() {
        return {
            title: '',

            tableData: [],
            page: 1,
            pageSize: 10,
            total: 0,

            activeItem: null,
            isShowEditMirageTpl: false,
            isShowTplConfig: false,
        }
    },
    created() {
        this.getCourseTplListApi()
    },
    methods: {
        onReset() {
            this.title = ''
            this.onPaginationChange('page', 1)
        },
        onPaginationChange(type, value) {
            if (type === 'size') {
                this.pageSize = value
                this.page = 1
            } else {
                this.page = value
            }
            this.$nextTick(this.getCourseTplListApi)
        },

        // 编辑
        onShowEditMirageTpl(item) {
            this.activeItem = item
            this.isShowEditMirageTpl = true
        },
        onCloseEditMirageTpl(state) {
            this.activeItem = null
            this.isShowEditMirageTpl = false
            if (state) this.getCourseTplListApi()
        },
        // 操作配置
        onShowEditTplConfig(item) {
            this.activeItem = item
            this.isShowTplConfig = true
        },
        onCloseEditTplConfig(state) {
            this.activeItem = null
            this.isShowTplConfig = false
            if (state) this.getCourseTplListApi()
        },

        onDelTpl(item) {
            delCourseTpl({ id: item.id }).then(() => {
                this.$message.success('删除成功')
                this.getCourseTplListApi()
            })
        },

        async getCourseTplListApi() {
            const { page, pageSize, title } = this
            let params = {
                orderBy: '-id',
                page,
                size: pageSize,
                showPage: true,
                title,
            }

            params = filterQueryEmpty(params)
            const res1 = await getCourseTplList(params)
            if (res1.data.list.length !== 0) {
                const _courseIds = []
                res1.data.list.forEach((item) => {
                    if (!_courseIds.includes(item.srcCourseId)) _courseIds.push(item.srcCourseId)
                })
                const res2 = await getCourseList({ ids: _courseIds })
                const _courseMap = {}
                res2.data.forEach((item) => {
                    _courseMap[item.id] = item
                })
                res1.data.list.forEach((item) => {
                    const _action = item.action ? JSON.parse(item.action) : []
                    item.pushNum = _action.length
                    item.courseInfo = _courseMap[item.srcCourseId]
                })
            }
            this.tableData = res1.data.list
            this.page = res1.data.pageNum
            this.total = res1.data.total
        },
    },
}
</script>
