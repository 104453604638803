<template>
    <el-drawer
        :title="title"
        :visible.sync="isShow"
        class="custom_drawer"
        size="640px"
        :before-close="onClose"
        @opened="onOpened"
    >
        <div class="action_list">
            <div class="item" v-for="(item, index) in actionList" :key="item.uid">
                <i class="el-icon-delete" @click="onDelAction(index)" />
                <el-input class="start" disabled :value="`${item.start}s`" />
                <el-input class="start_time" v-model="item.startTime" @change="onStartChange(item, index)" />
                <el-select class="action" v-model="item.action">
                    <el-option v-for="i in actionTypeList" :key="i.val" :label="i.name" :value="i.val" />
                </el-select>
                <el-input
                    v-if="item.action === 'lottery_result'"
                    class="nickname"
                    placeholder="请输入中奖人昵称"
                    v-model.trim="item.nickname"
                    :maxlength="32"
                    show-word-limit
                    @change="onNicknameChange(item, index)"
                />

                <div class="error" v-if="item.error">{{ item.error }}</div>
            </div>
        </div>

        <el-button icon="el-icon-plus" type="primary" plain @click="onAddAction" size="mini">新增推送</el-button>
        <el-button plain size="mini" @click="onSort">按时间排序</el-button>

        <div class="drawer_footer">
            <el-button @click="onClose">取 消</el-button>
            <el-button type="primary" @click="onSubmit">确定</el-button>
        </div>
    </el-drawer>
</template>
<script>
import { saveCourseTpl } from '@/js/api/courseApi.js'
import { supplementZero } from '@/js/utils.js'
import { v4 as uuidv4 } from 'uuid'

export default {
    props: ['isShow', 'activeItem'],
    data() {
        return {
            title: '',
            actionList: [],

            actionTypeList: [
                { name: '抽奖开始', val: 'lottery_start' },
                { name: '抽奖结束', val: 'lottery_result' },
                { name: '商品弹窗', val: 'sell_product_pop' },
            ],
            productList: [],
        }
    },
    methods: {
        onOpened() {
            this.title = `${this.activeItem.title} - 推送配置`
            // 初始化 actionList 配置
            if (this.activeItem && this.activeItem.action) {
                const _action = JSON.parse(this.activeItem.action)
                _action.forEach((item) => {
                    this.actionList.push({
                        uid: uuidv4(),
                        startTime: this.formatTime(item.start),
                        error: '',
                        ...item,
                    })
                })
            }
        },
        onClose() {
            this.title = ''
            this.actionList = []
            this.$emit('onClose')
        },

        // 字段校验
        onStartChange(item, index) {
            this.actionList[index]['error'] = ''
            const _timePattern = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/
            if (_timePattern.test(item.startTime)) {
                this.actionList[index]['start'] = this.getTimeNum(item.startTime)
            } else {
                this.actionList[index]['error'] = '日期格式错误'
            }
        },
        onNicknameChange(item, index) {
            this.actionList[index]['error'] = ''
            if (!item.nickname) {
                this.actionList[index]['error'] = '请输入中奖人昵称'
            }
        },

        onAddAction() {
            this.actionList.push({
                uid: uuidv4(),
                startTime: '00:00:00',
                error: '',
                action: 'sell_product_pop',
                start: 0,
                nickname: '',
            })
        },
        onDelAction(index) {
            this.actionList.splice(index, 1)
        },
        onSort() {
            this.actionList.sort((a, b) => a.start - b.start)
        },

        onSubmit() {
            for (let index = 0; index < this.actionList.length; index++) {
                const item = this.actionList[index]
                this.onStartChange(item, index)
                if (item.action === 'lottery_result') {
                    this.onNicknameChange(item, index)
                }
            }

            for (let index = 0; index < this.actionList.length; index++) {
                const item = this.actionList[index]
                if (item.error) {
                    return this.$message.error('请将信息填写完整')
                }
            }
            const params = { ...this.activeItem, action: [] }
            this.actionList.forEach((i) => {
                const _a = { action: i.action, start: i.start, nickname: i.nickname }
                if (i.nickname) _a.nickname = i.nickname
                params.action.push(_a)
            })
            saveCourseTpl(params).then(() => {
                this.$message.success('编辑成功')
                this.onClose()
                this.$emit('onClose', true)
            })
        },
        formatTime(timeNum) {
            const _hours = parseInt(timeNum / 3600)
            const _minutes = parseInt((timeNum % 3600) / 60)
            const _seconds = parseInt(timeNum % 60)
            return `${supplementZero(_hours)}:${supplementZero(_minutes)}:${supplementZero(_seconds)}`
        },
        getTimeNum(time) {
            const _timeParts = time.split(':')
            const _hours = parseInt(_timeParts[0])
            const _minutes = parseInt(_timeParts[1])
            const _seconds = parseInt(_timeParts[2])
            return _hours * 3600 + _minutes * 60 + _seconds
        },
    },
}
</script>
<style lang="less" scoped>
.action_list {
    margin: 0 0 20px 0;
}
.item {
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 28px;
    .el-icon-delete {
        flex-shrink: 0;
        margin: 0 12px;
        color: #f56c6c;
        font-size: 20px;
        cursor: pointer;
    }
    .start {
        width: 72px;
        flex-shrink: 0;
    }
    .start_time {
        width: 90px;
        flex-shrink: 0;
    }
    .action {
        width: 160px;
        flex-shrink: 0;
    }
    .nickname {
        flex-grow: 1;
    }

    .error {
        position: absolute;
        bottom: 10px;
        color: #f56c6c;
        left: 48px;
        font-size: 12px;
    }
}
</style>
