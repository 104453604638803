<template>
    <el-dialog :title="title" :visible.sync="isShow" width="420px" @opened="onOpened" :before-close="onClose">
        <el-form v-if="formData" ref="formData" :model="formData" :rules="rules" label-width="100px">
            <el-form-item prop="title" label="模版名称">
                <el-input v-model.trim="formData.title" :maxlength="32" show-word-limit placeholder="请输入模版名称" />
            </el-form-item>
            <el-form-item prop="srcCourseId" label="模版课程">
                <el-select
                    style="width: 100%"
                    v-model="formData.srcCourseId"
                    filterable
                    remote
                    placeholder="请输入关键词进行搜索"
                    :remote-method="getCourseListApi"
                    :loading="loading"
                    @change="onCourseChange"
                    :disabled="!!courseInfo"
                >
                    <el-option disabled label="显示前20条" value="item.id" />
                    <el-option-group v-for="item in courseList" :key="item.id" :label="item.addTime">
                        <el-option :label="`【${item.id}】 ${item.title}`" :value="item.id" />
                    </el-option-group>
                </el-select>
            </el-form-item>
        </el-form>
        <span slot="footer">
            <el-button @click="onClose">取 消</el-button>
            <el-button type="primary" @click="onSubmit">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { getCourseList, saveCourseTpl } from '@/js/api/courseApi.js'

export default {
    props: ['isShow', 'activeItem', 'courseInfo'],
    data() {
        return {
            title: '',
            formData: null,
            rules: {
                title: [{ required: true, message: '请输入', trigger: 'change' }],
                srcCourseId: [{ required: true, message: '请选择', trigger: 'change' }],
            },
            loading: false,
            courseList: [],
        }
    },
    methods: {
        onOpened() {
            this.title = '新建伪直播模版'
            let formData = {
                title: '',
                srcCourseId: '',
                liveId: '',
                action: [],
            }
            if (this.activeItem) {
                this.title = `${this.activeItem.title} - 编辑`
                formData = { ...formData, ...this.activeItem }
                this.courseList = [this.activeItem.courseInfo]
            } else if (this.courseInfo) {
                this.courseList = [this.courseInfo]
                formData.srcCourseId = this.courseInfo.id
                formData.liveId = this.courseInfo.liveId
            } else {
                this.getCourseListApi('')
            }
            this.formData = formData
        },
        onClose() {
            this.formData = null
            this.$emit('onClose')
        },

        onCourseChange() {
            for (let index = 0; index < this.courseList.length; index++) {
                if (this.courseList[index]['id'] === this.formData.srcCourseId) {
                    this.formData.liveId = this.courseList[index]['liveId']
                    break
                }
            }
        },

        onSubmit() {
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    saveCourseTpl(this.formData).then(() => {
                        this.$message.success('编辑成功')
                        this.onClose()
                        this.$emit('onClose', true)
                    })
                }
            })
        },
        getCourseListApi(query) {
            this.loading = true
            const params = { page: 1, size: 20, showPage: false, provider: 'talkfun', mirageTypes: [0] }
            if (query) params.title = query
            getCourseList(params).then((res) => {
                this.courseList = res.data
                this.loading = false
            })
        },
    },
}
</script>
